import * as React from "react"

import Layout from "../components/layout/layout"
import Seo from "../components/seo"
import PageHero from "../components/common/page-hero"

const ContingencySearchPage = () => (
  <Layout>
    <Seo title="Contingency Search" />
    <PageHero title="Contingency Search" />

    <div className="bg-gray-100">
      <div className="w-11/12 max-w-screen-lg mx-auto pt-16 pb-20">
        <p className="text-lg mb-6">HireLive’s Contingency Search Division works with clients across the United States helping to find and present the most qualified candidates to help your company grow. Each project is assigned to an experienced HireLive Recruiter who will source, cold call, network, screen, and identify the most qualified candidates to meet your specific needs. HireLive has the tools, experience and resources to find the caliber of candidate you are looking for.</p>
        <h2 className="text-2xl mb-8">HireLive Search is committed to:</h2>
        <p className="text-lg mb-6"><b>Quality:</b> We find qualified individuals for you to meet with face to face who make an immediate impact on your business.</p>
        <p className="text-lg mb-6"><b>Experience:</b> All HireLive recruiters are experienced with a great track record of success and average more than 10+ years each in the recruiting industry.</p>
        <p className="text-lg mb-6"><b>Targeted Searches:</b> HireLive has recruiters that specialize in specific areas, such as: sales, sales management, IT, marketing, finance, retail, manufacturing, customer service, warehouse, and much more.</p>
        <p className="text-lg mb-6"><b>Recruiting Tools:</b> HireLive has a proprietary database of more than 6 million resumes. All of our recruiters have access to all major Job Boards and databases and LinkedIn recruiter to find that perfect candidate for you.</p>
        <p className="text-lg mb-6"><b>No Upfront Cost:</b> With contingency search, there is no cost to you until you make your hire and they start their first day with your company.</p>
        <p className="text-lg mb-6"><b>Guarantee:</b> If the candidate does not work out for you, each placement has a guarantee period.</p>
      </div>
    </div>
  </Layout>
)

export default ContingencySearchPage